<template>

<div class="main" :class="[ catalogType, sectionsClass ]">

	<div class="catalog">

		<marquee :link_type="catalogType" :link_name="link_name" v-if="!loading && link_name && !filtersAreSet"></marquee>

		<component
			class="section"
			v-bind:is="element.name"
			:options="element.options"
			:config="{ index: index + 1, hash: element.hash }"
			:link_type="catalogType"
			:link_name="link_name"
			v-for="(element, index) in getTemplateData.pageSections" :key="index"
			v-if="element.name == 'featured-product-row' && !loading && link_name && !filtersAreSet">
		</component>

		<h1>{{ catalogScope.title }}</h1>

		<div class="filters catalog-filters" v-if="pagination.total_pages > 1 || hasFilters">

			<h3 v-if="hasFilters" @click="setHideFilters()">Filter <i class="fas fa-angle-up" v-if="!hideFilters"></i><i class="fas fa-angle-down" v-else="!hideFilters"></i></h3>

			<div class="controls">
				<p v-if="!loading && pagination.total_items > 0">Showing {{ showingRange }} of {{ pagination.total_items }}</p>
				<button v-if="pagination.total_pages > 1" :class="['button', !loading && filters.page > 1 ? 'action' : 'cancel']" @click="loadPage('prev')"><i class="fas fa-chevron-left"></i><span style="display:none;">Prev.</span></button>
				<label for="pagination-top" style="display:none;">Page Number</label><input v-if="pagination.total_pages > 1" type="text" id="pagination-top" v-model="currentPage" :disabled="loading" @change="loadPage(currentPage)">
				<button v-if="pagination.total_pages > 1" :class="['button', !loading && filters.page < pagination.total_pages ? 'action' : 'cancel']" @click="loadPage('next')"><i class="fas fa-chevron-right"></i><span style="display:none;">Next</span></button>
			</div>

			<div v-if="!hideFilters && Object.keys(filterLists.departments).length > 1">
				<label for="filter-department">Department</label>
				<select id="filter-department" v-model="filters.department_id" @change="setFilter()">
					<option value="">Select...</option>
					<option v-for="(department, index) in filterLists.departments" :value="department.department_id">{{ department.description }}</option>
				</select>
				<button :class="['button clear-filter', filters.department_id ? 'action' : 'cancel']" @click="clearFilter('department_id')"><i class="fas fa-minus-circle"></i><span style="display:none;">Cancel</span></button>
			</div>

			<div v-if="!hideFilters && Object.keys(filterLists.departments).length < 2 && Object.keys(filterLists.categories).length > 1">
				<label for="filter-category">Category</label>
				<select id="filter-category" v-model="filters.category_id" @change="setFilter()">
					<option value="">Select...</option>
					<option v-for="(category, index) in filterLists.categories" :value="category.category_id">{{ category.description }}</option>
				</select>
				<button :class="['button clear-filter', filters.category_id ? 'action' : 'cancel']" @click="clearFilter('category_id')"><i class="fas fa-minus-circle"></i><span style="display:none;">Cancel</span></button>
			</div>

			<div v-if="!hideFilters && Object.keys(filterLists.manufacturers).length > 1">
				<label for="filter-manufacturer">Manufacturer</label>
				<select id="filter-manufacturer" v-model="filters.manufacturer_id" @change="setFilter()">
					<option value="">Select...</option>
					<option v-for="(manufacturer, index) in filterLists.manufacturers" :value="manufacturer.manufacturer_id">{{ manufacturer.description }}</option>
				</select>
				<button :class="['button clear-filter', filters.manufacturer_id ? 'action' : 'cancel']" @click="clearFilter('manufacturer_id')"><i class="fas fa-minus-circle"></i><span style="display:none;">Cancel</span></button>
			</div>

			<div v-if="!hideFilters && Object.keys(filterLists.calibers).length > 1">
				<label for="filter-caliber">Caliber</label>
				<select id="filter-caliber" v-model="filters.caliber_id" @change="setFilter()">
					<option value="">Select...</option>
					<option v-for="(caliber, index) in filterLists.calibers" :value="caliber.caliber_id">{{ caliber.description }}</option>
				</select>
				<button :class="['button clear-filter', filters.caliber_id ? 'action' : 'cancel']" @click="clearFilter('caliber_id')"><i class="fas fa-minus-circle"></i><span style="display:none;">Cancel</span></button>
			</div>

			<div v-if="!hideFilters" class="extra-filters">
				<div v-if="!hideStoreLocation"><input type="checkbox" v-model="filters.in_store_only" @change="setFilter()"> Available In Store</div>
				<div><input type="checkbox" v-model="filters.state_compliant" @change="setFilter()"> {{ stateNames[getClient.state] || getClient.state }} Compliant</div>
			</div>

		</div>

		<div class="catalog-loading" v-if="loading"><span class="fas fa-spinner fa-spin"></span></div>

		<div class="section product-grid" style="margin-bottom: 0;">
			<product v-for="(product, index) in products" :key="'product-tile-' + index" :product="product"></product>
		</div>

		<div class="filters catalog-filters">
			<div class="controls">
				<p v-if="!loading && pagination.total_items > 0">Showing {{ showingRange }} of {{ pagination.total_items }}</p>
				<button v-if="pagination.total_pages > 1" :class="['button', !loading && filters.page > 1 ? 'action' : 'cancel']" @click="loadPage('prev')"><i class="fas fa-chevron-left"></i><span style="display:none;">Prev.</span></button>
				<label for="pagination-bottom" style="display:none;">Page Number</label><input v-if="pagination.total_pages > 1" type="text" id="pagination-bottom" v-model="currentPage" :disabled="loading" @change="loadPage(currentPage)">
				<button v-if="pagination.total_pages > 1" :class="['button', !loading && filters.page < pagination.total_pages ? 'action' : 'cancel']" @click="loadPage('next')"><i class="fas fa-chevron-right"></i><span style="display:none;">Next</span></button>
			</div>
		</div>

		<div v-if="!loading && products.length == 0" class="empty-list">
			{{ message }}
		</div>

	</div>

</div>

</template>

<script>
export default {
	name: 'catalog',
	props: [ 'link_name', 'search_term' ],
	data() {
		return {
			catalogScope: {
				title: 'Loading'
			},
			filters: {
				page: 1,
				search_term: '',
				link_type: '',
				link_name: '',
				department_id: '',
				category_id: '',
				manufacturer_id: '',
				caliber_id: '',
				in_store_only: false,
				state_compliant: false,
				flush: false
			},
			currentPage: 1,
			products: [],
			message: 'Loading',
			loading: true,
			hideFilters: true,
			pagination: {
				current_page: 1,
				total_pages: 1,
				items_per_page: 1,
				total_items: 0
			},
			filterLists: {
				calibers: [],
				manufacturers: [],
				categories: [],
				departments: []
			},
			haveFilterLists: false,
			forceGetFilters: false,
			hideStoreLocation: false,
			stateNames: {
				"AL": "Alabama",
				"AK": "Alaska",
				"AZ": "Arizona",
				"AR": "Arkansas",
				"CA": "California",
				"CO": "Colorado",
				"CT": "Connecticut",
				"DE": "Delaware",
				"DC": "District Of Columbia",
				"FL": "Florida",
				"GA": "Georgia",
				"HI": "Hawaii",
				"ID": "Idaho",
				"IL": "Illinois",
				"IN": "Indiana",
				"IA": "Iowa",
				"KS": "Kansas",
				"KY": "Kentucky",
				"LA": "Louisiana",
				"ME": "Maine",
				"MD": "Maryland",
				"MA": "Massachusetts",
				"MI": "Michigan",
				"MN": "Minnesota",
				"MS": "Mississippi",
				"MO": "Missouri",
				"MT": "Montana",
				"NE": "Nebraska",
				"NV": "Nevada",
				"NH": "New Hampshire",
				"NJ": "New Jersey",
				"NM": "New Mexico",
				"NY": "New York",
				"NC": "North Carolina",
				"ND": "North Dakota",
				"OH": "Ohio",
				"OK": "Oklahoma",
				"OR": "Oregon",
				"PA": "Pennsylvania",
				"PR": "Puerto Rico",
				"RI": "Rhode Island",
				"SC": "South Carolina",
				"SD": "South Dakota",
				"TN": "Tennessee",
				"TX": "Texas",
				"UT": "Utah",
				"VT": "Vermont",
				"VA": "Virginia",
				"WA": "Washington",
				"WV": "West Virginia",
				"WI": "Wisconsin",
				"WY": "Wyoming"
			}
		}
	},
	created() {
		if (this.getFilterListsPath == this.$route.path && !this.$route.query.flush) {
			this.catalogScope = this.getCatalogScope
			this.pagination = this.getPagination
			this.filterLists = this.getFilterLists
			if (this.hasFilters) {
				this.haveFilterLists = true
			}
		} else {
			this.updateFilterListsPath(this.$route.path)
			sessionStorage.removeItem('hide_filters')
		}
		this.filters.department_id = this.$route.query.department || ''
		this.filters.category_id = this.$route.query.category || ''
		this.filters.manufacturer_id = this.$route.query.manufacturer || ''
		this.filters.caliber_id = this.$route.query.caliber || ''
		this.filters.in_store_only = this.$route.query.in_store_only || false
		this.filters.state_compliant = this.$route.query.state_compliant || false
		this.filters.page = this.$route.query.page || 1
		if (this.$route.query.flush) {
			this.filters.flush = true
		}
		this.currentPage = this.filters.page
		if (sessionStorage.getItem('hide_filters')) {
			this.hideFilters = sessionStorage.getItem('hide_filters') == 'true'
		}
		this.getList()
	},
	computed: {
		catalogType() {
			return this.$route.fullPath.split('/')[1]
		},
		showingRange() {
			return (((this.pagination.current_page - 1) * this.pagination.items_per_page) + 1).toString() + ' - ' + Math.min( ((this.pagination.current_page - 1) * this.pagination.items_per_page) + this.pagination.items_per_page, parseInt(this.pagination.total_items) ).toString()
		},
		filtersAreSet() {
			return this.filters.page > 1 || this.filters.search_term || this.filters.manufacturer_id || this.filters.category_id || this.filters.department_id || this.filters.caliber_id
		},
		hasFilters() {
			if (this.filterLists.departments && this.filterLists.categories && this.filterLists.manufacturers && this.filterLists.calibers) {
				return Object.keys(this.filterLists.departments).length > 1 || Object.keys(this.filterLists.categories).length > 1 || Object.keys(this.filterLists.manufacturers).length > 1 || Object.keys(this.filterLists.calibers).length > 1
			} else {
				return false
			}
		},
		sectionsClass() {
			if (!this.getTemplateData.siteOptions) return
			return [
				(this.getTemplateData.siteOptions.fullWidth) ? 'full-width' : ''
			]
		}
	},
	methods: {
		getList() {
			this.filters.link_type = this.catalogType
			this.filters.link_name = this.link_name
			this.filters.search_term = this.search_term
			// don't acquire filters if user set a page, manufacturer_id or caliber_id
			if ((!this.forceGetFilters && this.$route.query.page && this.haveFilterLists) || this.filters.manufacturer_id || this.filters.caliber_id) { 
				this.filters.get_filters = false
			} else {
				this.filters.get_filters = true
			}
			this.products = []
			this.loading = true
			this.apiCall({ destination: 'get_list_products_frontend', data: this.filters }).then(obj => {
				this.loading = false
				this.filters.get_filters = false
				this.currentPage = this.filters.page
				if (obj.status != 'success' || !obj.list) {
					this.catalogScope.title = 'Oops!'
					this.message = obj.message
				} else {
					this.hideStoreLocation = obj.hide_store_location
					this.catalogScope = obj.catalog_scope
					this.updateCatalogScope(this.catalogScope)
					if (this.filters.search_term) {
						this.catalogScope.title += ': ' + this.filters.search_term
					}
					this.products = obj.list
					if (obj.pagination) {
						this.pagination = obj.pagination
					}
					this.updatePagination(this.pagination)
					if (this.products.length == 0) {
						this.message = 'No items found'
					} else {
						// this.getListFilters()
						if (this.haveFilterLists) {
							// no manufacturer_id, so replace manufacturers list
							if (!this.filters.manufacturer_id) {
								this.filterLists.manufacturers = obj.filter_lists.manufacturers
							}
							// no caliber_id, so replace calibers list
							if (!this.filters.caliber_id) {
								this.filterLists.calibers = obj.filter_lists.calibers
							}
						} else if (obj.filter_lists) {
							this.haveFilterLists = true
							this.filterLists = obj.filter_lists
							this.updateFilterLists(this.filterLists)
						}

					}
				}
			}).catch(error => {
				console.log(error)
			})
		},
		setFilter(page) {
			// populate the url query string
			let queryFilters = {}
			if (this.filters.department_id) {
				queryFilters.department = this.filters.department_id
			}
			if (this.filters.category_id) {
				queryFilters.category = this.filters.category_id
			}
			if (this.filters.manufacturer_id) {
				queryFilters.manufacturer = this.filters.manufacturer_id
			}
			if (this.filters.caliber_id) {
				queryFilters.caliber = this.filters.caliber_id
			}
			if (this.filters.in_store_only) {
				queryFilters.in_store_only = '1'
			}
			if (this.filters.state_compliant) {
				queryFilters.state_compliant = '1'
			}
			if (page) {
				queryFilters.page = page
			} else {
				queryFilters.page = 1
			}
			let clearFilters = false
			// in_store_only change needs to re-acquire filters
			if (this.$route.query.in_store_only && !this.filters.in_store_only) {
				clearFilters = true
			}
			if (!this.$route.query.in_store_only && this.filters.in_store_only) {
				clearFilters = true
			}
			// state_compliant change needs to re-acquire filters
			if (this.$route.query.state_compliant && !this.filters.state_compliant) {
				clearFilters = true
			}
			if (!this.$route.query.state_compliant && this.filters.state_compliant) {
				clearFilters = true
			}
			if (clearFilters) {
				this.filterLists = {
					calibers: [],
					manufacturers: [],
					categories: [],
					departments: []
				}
				this.updateFilterLists(this.filterLists)
			}
			// if (!this.getBtn) {
			// 	this.updateBtn('filter')
			// }
			this.$router.push({ query: queryFilters })
		},
		clearFilter(which) {
			if (this.filters[which] != '') {
				this.filters[which] = ''
				this.setFilter()
			}
		},
		setHideFilters() {
			this.hideFilters = !this.hideFilters
			// store current setting in session store
			sessionStorage.setItem('hide_filters', this.hideFilters)
		},
		loadPage(direction) {
			if (this.loading) { return }
			// this.updateBtn('page')
			if (direction == 'next' && this.filters.page < this.pagination.total_pages) {
				this.filters.page++
				this.setFilter(this.filters.page)
			} else if (direction == 'prev' && this.filters.page > 1) {
				this.filters.page--
				this.setFilter(this.filters.page)
			} else if (!isNaN(direction) && direction > 0 && direction <= this.pagination.total_pages) {
				this.filters.page = direction
				this.setFilter(this.filters.page)
			} else {
				this.currentPage = this.filters.page
			}
		}
	}
}
</script>

<style lang="scss">
.catalog-loading {
	font-size: 3em;
	padding: 2.5em 0;
	text-align: center;
	color: #666;
	background-color: #fff;
	max-width: 100%;
	@media (min-width: 1200px) {
		padding: 4em 0;
	}
}
.filters {
	background-color: #fff;
}
.catalog-filters {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-column-gap: 2em;
	align-items: center;
	color: black;
	h3 {
		grid-column: 1 / 2;
		cursor: pointer;
		user-select: none;
	}
	.controls {
		grid-column: 3 / 5;
		justify-self: end;
		p {
			display: inline-block;
			padding-right: 1em;
		}
		button {
			margin: 0 1rem;
		}
		input {
			width: 3em;
			text-align: center;
			padding: 0.5em;
			vertical-align: middle;
			margin: 0;
		}
	}
	label { display: block; }
	select { font-size: 1.1em; width: calc(100% - 2em); }
	.extra-filters { font-size: 0.9em; }
	.clear-filter { display: inline; margin: 0 0.2em 0.25em 0; padding: 0.6em 0.5em; height: 2em; width: 2em; }
	.clear-filter.cancel { background-color: transparent; color: #999; }
	.clear-filter.action { background-color: transparent; color: rgb(0, 49, 111); }
@media (max-width: 575px) {
	grid-template-columns: 1fr;
	.controls {
		grid-column: 1/2;
		grid-row: 1/2;
		text-align: right;
		p { display: block; }
	}
}
}
.product-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	@media (max-width: 994px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@media (max-width: 754px) {
		grid-template-columns: 1fr 1fr;
	}
	@media (max-width: 525px) {
		display: block;
	}
}
.empty-list {
	background-color: #fff;
	margin: 0;
	padding: 3em 0;
}
</style>
